import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "page-container relative" }
const _hoisted_3 = {
  class: "absolute -top-0 right-12 text-gray-400 z-100",
  "data-aos": "fade-left",
  "data-aos-duration": "1000"
}
const _hoisted_4 = { class: "mt-10" }
const _hoisted_5 = { class: "flex items-center justify-center gap-4 mt-6" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick", "disabled"]
const _hoisted_8 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.getIsLoadingUpdateTenantStatus)
    ? (_openBlock(), _createBlock($setup["BaseSpinner"], {
        key: 0,
        "full-screen": ""
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["TheHeader"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["TheClock"])
          ]),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["TenantDetailForm"], {
            "is-show-status-remark": true,
            tenantId: $setup.route.params.tenantId,
            "onUpdate:tenantData": $setup.updateTenantData
          }, null, 8, ["tenantId"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "btn bg-gray-500 hover:bg-gray-600 text-white py-2 w-24 rounded-lg",
            onClick: _withModifiers($setup.handleBack, ["prevent"])
          }, " Back ", 8, _hoisted_6),
          ($setup.tenantData?.Status == $setup.STATUS_TENANT.Pending)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn bg-red-500 hover:bg-red-600 text-white py-2 w-24 rounded-lg",
                onClick: _withModifiers($setup.openRejectModal, ["prevent"]),
                disabled: $setup.isSubmitting
              }, " Reject ", 8, _hoisted_7))
            : _createCommentVNode("", true),
          ($setup.tenantData?.Status == $setup.STATUS_TENANT.Pending)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn--green-primary hover:bg-green-600 text-white py-2 w-24 rounded-lg",
                onClick: _withModifiers($setup.handleApprove, ["prevent"]),
                disabled: $setup.isSubmitting
              }, " Approve ", 8, _hoisted_8))
            : _createCommentVNode("", true)
        ]),
        _createVNode($setup["TenantRejectFormDialog"], {
          tenantId: $setup.tenantId,
          isRejectModalOpen: $setup.isRejectModalOpen,
          "onUpdate:isRejectModalOpen": _cache[0] || (_cache[0] = ($event: any) => (($setup.isRejectModalOpen) = $event)),
          companyName: $setup.tenantData?.CompanyName
        }, null, 8, ["tenantId", "isRejectModalOpen", "companyName"]),
        _createVNode($setup["TheFooter"], { class: "page-footer" })
      ]))
}